/* eslint-disable */
import * as Types from '@graphcommerce/graphql-mesh/.mesh';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

export const RowPromoBannerDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"RowPromoBanner"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"rowPromoBanners"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"active"}},{"kind":"Field","name":{"kind":"Name","value":"displayText"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"raw"}}]}},{"kind":"Field","name":{"kind":"Name","value":"backgroundColor"}},{"kind":"Field","name":{"kind":"Name","value":"textColor"}},{"kind":"Field","name":{"kind":"Name","value":"linkColor"}}]}}]}}]} as unknown as DocumentNode<RowPromoBannerQuery, RowPromoBannerQueryVariables>;
export type RowPromoBannerQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type RowPromoBannerQuery = { rowPromoBanners: Array<{ active?: boolean | null, backgroundColor?: string | null, textColor?: string | null, linkColor?: string | null, displayText?: { raw: any } | null }> };

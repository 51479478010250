import { useIsomorphicLayoutEffect } from '@graphcommerce/framer-utils'
import { useApolloClient, useQuery } from '@graphcommerce/graphql'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { CustomerTokenDocument, CustomerTokenQuery } from '@graphcommerce/magento-customer/hooks/CustomerToken.gql'

export type UseCustomerSessionOptions = { hydration?: boolean }

export type UseCustomerSessionReturn =
  | Partial<
    Omit<NonNullable<CustomerTokenQuery['customerToken']>, '__typename'> & {
      called: boolean
    }
  > & { loggedIn: boolean; requireAuth: boolean }

export function useCustomerSession(
  options: UseCustomerSessionOptions = {},
): UseCustomerSessionReturn {
  const { hydration = true } = options
  const [hydrating, setHydrating] = useState(!hydration)
  useIsomorphicLayoutEffect(() => setHydrating(false), [])
  const skip = hydrating

  const router = useRouter();
  const client = useApolloClient();

  useEffect(() => {
    if (router.query.tkn) {
      client.writeQuery({
        query: CustomerTokenDocument,
        data: {
          customerToken: {
            __typename: "CustomerToken",
            token: router.query.tkn as string,
            createdAt: new Date().toString(),
            valid: true
          }
        }
      });
    }
  }, [router]);

  const { called, data } = useQuery(CustomerTokenDocument, { skip })

  const token = data?.customerToken

  if (!token) return { called, loggedIn: false, requireAuth: true }

  return {
    ...token,
    called,
    loggedIn: Boolean(token?.token && token.valid),
    requireAuth: Boolean(!token || !token.valid),
  }
}

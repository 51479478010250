import { RichText } from "@graphcommerce/graphcms-ui";
import { IconSvg, breakpointVal, iconArrowForward, responsiveVal } from "@graphcommerce/next-ui";
import { Box, Link, SvgIcon } from "@mui/material";

function BannerPromo(props) {
  const { backgroundColor, displayText, textColor, linkColor } = props;
  return (
    <Box sx={{
      backgroundColor,
      height: responsiveVal(37, 47),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <RichText
        {...displayText}
        sxRenderer={{
          'paragraph': (theme) => ({
            textAlign: 'center',
            maxWidth: '100%',
            color: textColor,
            ...breakpointVal('fontSize', 10, 18, theme.breakpoints.values),
            '& strong': (theme) => ({
              fontWeight: 'strong',
              ml: 1,
            }),
          }),
          'link': {
            color: linkColor,
            ml: 1,
          },
        }}
        renderers={{
          link: ({ href, openInNewTab, ...props }) => (
            <>
              <Link href={href} underline='hover' {...props} target={openInNewTab ? '_blank' : undefined} />
              <Link href={href} underline='hover' target={openInNewTab ? '_blank' : undefined}>
                <IconSvg src={iconArrowForward} sx={{ pt: 1, color: linkColor }} />
              </Link>
            </>
          ),
        }}
      />
    </Box>
  );
}

export default BannerPromo;
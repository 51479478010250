/* eslint-disable */
import * as Types from '@graphcommerce/graphql-mesh/.mesh';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

export const UseChipLabCustomerValidateTokenDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"UseChipLabCustomerValidateToken"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"customer"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"email"}}]}}]}}]} as unknown as DocumentNode<UseChipLabCustomerValidateTokenQuery, UseChipLabCustomerValidateTokenQueryVariables>;
export type UseChipLabCustomerValidateTokenQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UseChipLabCustomerValidateTokenQuery = { customer?: { email?: string | null } | null };
